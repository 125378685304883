<template>
  <div>
    <div v-if="qryAccess" style="overflow: scroll;">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="stick-corner">Подразделение</th>
            <th v-for="role in qryRole.recordset.records" :key="`role_${ role.id.value }`" class="stick-th">{{ role.name.value }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="unit in qryUnit.recordset.records" :key="`unit_${ unit.id.value}`">
            <td class="stick-col table-light">{{ unit.name.value }}</td>
            <td v-for="role in qryRole.recordset.records" :key="`unit_${ unit.id.value }_role_${ role.id.value }`">
              <cbs-ind-access-cell :record="getRecord(role, unit)" :key-access="keyAccess"
                                   :ro-value="roValue" :rw-value="rwValue"
                                   @update="onUpdateCell(role, unit, $event)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import useCubus from '@/cubus/services/useCubus'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsIndAccessCell from '@/cubus/components/cbs-form/custom-forms/indicator-access/CbsIndAccessCell.vue'

export default {
  name: 'CbsBudgetRequestAccess',
  components: {
    CbsIndAccessCell,
    CbsDebug,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      objUnit: null,
      qryUnit: null,
      objRole: null,
      qryRole: null,
      objAccessType: null,
      qryAccessType: null,
      objAccess: null,
      qryAccess: null,
      keyRole: null,
      keyUnit: null,
      keyAccess: null,
      roValue: null,
      rwValue: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loadObjRole()
    },
    async loadObjRole() {
      this.objRole = await useCubus.loadObject(this, { objectsid: 'role' })
      if (this.objRole) {
        await this.loadQryRole()
      }
    },
    async loadQryRole() {
      this.qryRole = await useCubus.loadQuery(this, { objectid: this.objRole.object.id, pageSize: 100000 })
      if (this.qryRole) {
        await this.loadObjUnit()
      }
    },
    async loadObjUnit() {
      this.objUnit = await useCubus.loadObject(this, { objectsid: 'business_unit' })
      if (this.objUnit) {
        await this.loadQryUnit()
      }
    },
    async loadQryUnit() {
      this.qryUnit = await useCubus.loadQuery(this, { objectid: this.objUnit.object.id, pageSize: 100000 })
      if (this.qryUnit) {
        await this.loadObjAccessType()
      }
    },
    async loadObjAccessType() {
      this.objAccessType = await useCubus.loadObject(this, { objectsid: 'accesstype' })
      if (this.objAccessType) {
        await this.loadQryAccessType()
      }
    },
    async loadQryAccessType() {
      this.qryAccessType = await useCubus.loadQuery(this, { objectid: this.objAccessType.object.id, pageSize: 100000 })
      if (this.qryAccessType) {
        this.roValue = this.qryAccessType.recordset.records.find(r => r.sid.value === 'ro').id.value
        this.rwValue = this.qryAccessType.recordset.records.find(r => r.sid.value === 'rw').id.value
        await this.loadObjAccess()
      }
    },
    async loadObjAccess() {
      this.objAccess = await useCubus.loadObject(this, { objectsid: 'budget_request_access' })
      if (this.objAccess) {
        await this.loadQryAccess()
      }
    },
    async loadQryAccess() {
      const params = {
        objectid: this.objAccess.object.id,
        pageSize: 100000,
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'del' },
                  { type: { sid: 'value' }, value: false },
                ],
              },
            ],
          },
        },
      }
      this.qryAccess = await useCubus.loadQuery(this, params)
      if (this.qryAccess) {
        this.keyRole = this.qryAccess.columns.find(c => c.entitysid === 'role').key
        this.keyUnit = this.qryAccess.columns.find(c => c.entitysid === 'business_unit').key
        this.keyAccess = this.qryAccess.columns.find(c => c.entitysid === 'indicatoraccess').key
      }
    },
    getRecord(role, unit) {
      const rec = this.qryAccess.recordset.records.find(r => r[this.keyRole].value === role.id.value && r[this.keyUnit].value === unit.id.value)
      if (rec) return rec
      const newrec = this.getNewRecord(role, unit)
      this.qryAccess.recordset.records.push(newrec)
      return newrec
    },
    getNewRecord(role, unit) {
      const n = {}
      this.qryAccess.columns.forEach(c => {
        if (c.default) {
          n[c.key] = {}
          n[c.key].value = c.default.value
          n[c.key].title = c.default.title
        }
      })
      n[this.keyRole].value = role.id.value
      n[this.keyRole].title = role.name.value
      n[this.keyUnit].value = unit.id.value
      n[this.keyUnit].title = unit.name.value
      return JSON.parse(JSON.stringify(n))
    },
    onUpdateCell(role, unit, value) {
      const rec = this.getRecord(role, unit)
      if (value === 'no') {
        rec[this.keyAccess].value = null
        rec[this.keyAccess].title = null
      } else {
        rec[this.keyAccess].value = this.qryAccessType.recordset.records.find(r => r.sid.value === value).id.value
        rec[this.keyAccess].title = this.qryAccessType.recordset.records.find(r => r.sid.value === value).name.value
      }
      rec[this.keyAccess].status = 'changed'
      this.saveRecord(rec)
    },
    async saveRecord(record) {
      const params = { objectid: this.objAccess.object.id, record }
      const saved = await useCubus.saveRecord(this, params)
      if (saved) {
        this.afterSave(record, saved)
      }
    },
    afterSave(initRec, savedRec) {
      savedRec.status = 'saved'
      this.qryAccess.columns.forEach(fld => {
        if (fld.key !== 'actions') {
          initRec[fld.key].value = savedRec[fld.key].value
          initRec[fld.key].title = savedRec[fld.key].title
        }
      })
      initRec.status = 'saved'
    },
  },
}
</script>

<style scoped>
.stick-corner {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
}
.stick-th {
  position: sticky;
  top: 0;
  z-index: 2;
}
.stick-col {
  position: sticky;
  left: 0;
  z-index: 1;
}
</style>
