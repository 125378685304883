<template>
  <div>
    <div style="overflow: scroll; max-height: 75vh;">
      <table v-if="dataReady" class="table table-bordered">
        <thead>
          <tr :id="`th_${uuid}`">
            <th style="position: sticky; left: 0; top: 0; z-index: 4">Показатель</th>
            <th v-for="mn in monthnames" :key="'hm_' + mn" style="position: sticky; top: 0; z-index: 3;">{{ mn + '-' + (baseYear - 2000) }}</th>
            <th v-for="yr in years" :key="'hy_' + yr" style="position: sticky; top: 0; z-index: 3;">{{ baseYear + yr }}</th>
          </tr>
        </thead>
        <tbody v-for="ind in innerIndicators" :key="'ind_' + ind.id.value" style="border-bottom: 0; border-top: 0;">
          <tr v-if="!isByProduct(ind)">
            <td class="table-light" style="position: sticky; left: 0; z-index: 2;">
              <feather-icon v-if="isByProduct(ind)"
                            size="16" style="cursor: pointer;"
                            :icon="ind.expanded ? 'MinusSquareIcon' : 'PlusSquareIcon'"
                            @click="clickIndExpand(ind)"
              />
              {{ ind.name.value }}
              <span v-if="metric(ind)">, {{ metric(ind) }}</span>
            </td>
            <td v-for="mnth in months" :key="'mnth_' + mnth" style="padding: 0;">
              <kbv-bis-table-cell
                :record="getRecord(constrDateString('month', mnth), ind, null)"
                :key-value="keyVal"
                :indicator="ind"
                @edit="onCellEdit('month', mnth, ind, null, $event)"
              />
            </td>
            <td v-for="yr in years" :key="'yr_' + (baseYear + yr)" style="padding: 0;">
              <kbv-bis-table-cell
                :record="getRecord(constrDateString('year', yr), ind, null)"
                :key-value="keyVal"
                :indicator="ind"
                @edit="onCellEdit('year', yr, ind, null, $event)"
              />
            </td>
          </tr>
          <tr v-if="isByProduct(ind)">
            <td class="table-light" style="position: sticky; left: 0; z-index: 2;">
              <feather-icon v-if="isByProduct(ind)"
                            size="16" style="cursor: pointer;"
                            :icon="ind.expanded ? 'MinusSquareIcon' : 'PlusSquareIcon'"
                            @click="clickIndExpand(ind)"
              />
              {{ ind.name.value }}
              <span v-if="metric(ind)">, {{ metric(ind) }}</span>
            </td>
            <td colspan="16" class="table-light"></td>
          </tr>
          <tr v-if="isByProduct(ind)" style="padding: 0;">
            <td colspan="17" style="padding: 0;">
              <cbs-collapse :trigger="ind.expanded" style="padding: 1rem;">
                <table class="table table-bordered">
                  <thead>
                    <tr :id="`th_${uuid}`">
                      <th style="position: sticky; left: 0; z-index: 2;">Продукт</th>
                      <th v-for="mn in monthnames" :key="'hm_' + mn">{{ mn + '-' + (baseYear - 2000) }}</th>
                      <th v-for="yr in years" :key="'hy_' + yr">{{ baseYear + yr }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="prod in products" :key="'ind_' + ind.id.value + '_prod_' + prod.id.value">
                      <td class="table-light" style="position: sticky; left: 0; z-index: 2;">
                        {{ prod.name.value }}
                        <span v-if="metric(prod)">, {{ metric(prod) }}</span>
                      </td>
                      <td v-for="mnth in months" :key="'mnth_' + mnth" style="padding: 0;">
                        <kbv-bis-table-cell
                          :record="getRecord(constrDateString('month', mnth), ind, prod)"
                          :key-value="keyVal"
                          :indicator="ind"
                          @edit="onCellEdit('month', mnth, ind, prod, $event)"
                        />
                      </td>
                      <td v-for="yr in years" :key="'yr_' + (baseYear + yr)" style="padding: 0;">
                        <kbv-bis-table-cell
                          :record="getRecord(constrDateString('year', yr), ind, prod)"
                          :key-value="keyVal"
                          :indicator="ind"
                          @edit="onCellEdit('year', yr, ind, prod, $event)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </cbs-collapse>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <cbs-data-query v-if="objData"
      hidden
      :object="objData"
      :filter="dataFilter()"
      :page-size="100000"
      @load="onLoadData"
    />

    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsDataQuery from '@/cubus/components/query/CbsDataQuery.vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import KbvBisTableCell from '@/cubus/components/cbs-form/custom-forms/kbv-budget-indicator/KbvBISTableCell.vue'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'

export default {
  name: 'KbvBisTable',
  components: {
    CbsCollapse,
    KbvBisTableCell,
    CbsDataQuery,
    CbsDebug,
  },
  props: {
    scenario: {
      type: Object,
      default: null,
    },
    indicators: {
      type: Array,
      default: null,
    },
    baseYear: {
      type: Number,
      default: null,
    },
    objData: {
      type: Object,
      default: null,
    },
    keyByProduct: {
      type: String,
      default: null,
    },
    products: {
      type: Array,
      default: null,
    },
    keyMetric: {
      type: String,
      default: null,
    },
    keyScenario: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      uuid: useCubus.guid(),
      indData: null,
      monthnames: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      years: [1, 2, 3, 4],
      keyVal: null,
      keyDate: null,
      keyInd: null,
      keyScn: null,
      keyProduct: null,
      innerIndicators: JSON.parse(JSON.stringify(this.indicators)),
      dataReady: false,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {},
    getRecord(dt, indicator, product) {
      if (!this.indData) return null
      return this.indData.recordset.records.find(r => r[this.keyInd].value === indicator.id.value
        && (product === null || r[this.keyProduct].value === product.id.value)
        && r[this.keyDate].value.startsWith(dt))
    },
    constrDateString(period, index) {
      if (period === 'month') return `01.${index < 10 ? '0' : ''}${index}.${this.baseYear}`
      if (period === 'year') return `01.01.${(this.baseYear + index)}`
      return null
    },
    onLoadData(data) {
      this.indData = data
      // this.renderTable()
      this.keyDate = this.indData.columns.find(c => c.entitysid === 'date').key
      this.keyInd = this.indData.columns.find(c => c.entitysid === 'budget_indicator').key
      this.keyVal = this.indData.columns.find(c => c.entitysid === 'indicator_value').key
      this.keyScn = this.indData.columns.find(c => c.entitysid === 'scenario').key
      this.keyProduct = this.indData.columns.find(c => c.entitysid === 'budget_product').key
      this.dataReady = true
    },
    dataFilter() {
      return {
        isactive: true,
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [],
          conds: [
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                { type: { sid: 'entitysid' }, value: 'del' },
                { type: { sid: 'value' }, value: false },
              ],
            },
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                { type: { sid: 'entitysid' }, value: 'scenario' },
                { type: { sid: 'value' }, value: this.scenario[this.keyScenario].value },
              ],
            },
          ],
        },
      }
    },
    getNewRecord(ind, dt, prod, value) {
      console.log('getNewRecord start')
      const res = {}
      this.indData.columns.forEach(c => {
        res[c.key] = c.default
      })
      console.log('default ready')
      res.del.value = false
      console.log('check 1')
      res[this.keyScn].value = this.scenario[this.keyScenario].value
      console.log('check 2')
      res[this.keyScn].title = this.scenario[this.keyScenario].title
      console.log('check 3')
      res[this.keyDate].value = dt
      console.log('check 4')
      res[this.keyInd].value = ind.id.value
      console.log('check 5')
      res[this.keyInd].title = ind.name.value
      console.log('values changed')
      if (prod) {
        res[this.keyProduct].value = prod.id.value
        res[this.keyProduct].title = prod.name.value
      }
      res[this.keyVal].value = value
      return JSON.parse(JSON.stringify(res))
    },
    getRecordToSave(srcRec, newValue, ind, prod, dt) {
      console.log('getRecordToSave start')
      if (srcRec) {
        console.log('srcRec')
        if (srcRec[this.keyVal].value !== newValue) {
          console.log('change values')
          srcRec[this.keyVal].value = newValue
          srcRec[this.keyVal].status = 'changed'
          srcRec.status = 'changed'
          console.log('return changed')
          return srcRec
        }
        console.log('same value')
        return null
      }
      console.log('no srcRec')
      if (newValue === 0) {
        console.log('newValue = 0')
        return null
      }
      console.log('newValue != 0')
      const newrec = this.getNewRecord(ind, dt, prod, newValue)
      console.log('newrec ready')
      return newrec
    },
    saveRecord(record, caller) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'saverecord',
          param: {
            objectid: this.objData.object.id,
            record,
          },
        },
      })
        .then(response => {
          console.log('save record response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.threadSaveRecord(response.data.thread, record, caller)
          } else {
            useCubus.toastError(this, 'No thread name provided.')
          }
        })
        .catch(error => {
          console.log('save record error', error)
        })
    },
    threadSaveRecord(thread, record, caller) {
      useJwt.query({ query: { method: 'thread', param: { threadname: thread } } })
        .then(response => {
          console.log('threadSaveRecord response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread.status === 'error') {
            useCubus.toastError(this, response.data.thread.error)
          } else if (response.data.thread.status === 'done') {
            this.afterSave(record, response.data.thread.result.record)
          } else {
            this.delaySaveRecord(thread, record, caller)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    delaySaveRecord(thread, record, caller) {
      setTimeout(() => this.threadSaveRecord(thread, record, caller), 200)
    },
    afterSave(initRec, savedRec) {
      savedRec.status = 'saved'
      if (initRec.id.value === null) {
        this.addNewRecord(savedRec)
      } else {
        this.indData.columns.forEach(fld => {
          initRec[fld.key] = savedRec[fld.key]
        })
        initRec.status = 'saved'
      }
    },
    addNewRecord(rec) {
      // this.records.push(rec)
      this.indData.recordset.records.push(rec)
      // target.innerText = rec[this.keyVal].value
    },
    onCellEdit(period, periodNum, ind, prod, newValue) {
      console.log(`onCellEdit : period = ${period}, periodNum = ${periodNum}, ind = ${ind}, prod = ${prod}, newValue = ${newValue}`)
      const dt = this.constrDateString(period, periodNum)
      console.log('dt ready')
      const rec = this.getRecord(dt, ind, prod)
      console.log('rec ready')
      const newrec = this.getRecordToSave(rec, newValue, ind, prod, dt)
      console.log('newrec ready')
      if (newrec) {
        console.log('newrec')
        this.saveRecord(newrec, 'kbv_bis_table')
        console.log('newrec saved')
      }
      console.log('onCellEdit end')
    },
    isByProduct(ind) {
      return ind[this.keyByProduct].value === true
    },
    clickIndExpand(ind) {
      if (ind.expanded) {
        ind.expanded = !ind.expanded
      } else {
        this.$set(ind, 'expanded', true)
      }
    },
    metric(ind) {
      return ind[this.keyMetric] ? ind[this.keyMetric].title : null
    },
  },
}
</script>

<style scoped>

</style>
