<template>
  <div style="width: 100%; height: 100%; text-align: center;">
    <div v-if="!isEdit" @click="editOn">
      {{ record ? record[keyValue].value : '\u00a0' }}
    </div>
    <div v-show="isEdit" :id="'edit_' + uuid" contenteditable="true" @blur="onBlur" />
  </div>
</template>

<script>
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'KbvBisTableCell',
  components: {},
  props: {
    record: {
      type: Object,
      default: null,
    },
    keyValue: {
      type: String,
      default: null,
    },
    indicator: {
      type: Object,
      default: null,
    },
  },
  emits: [
    'edit',
  ],
  data() {
    return {
      uuid: useCubus.guid(),
      isEdit: false,
    }
  },
  methods: {
    onBlur(event) {
      if (this.isEdit) {
        this.isEdit = false
        const newValue = Number(JSON.parse(JSON.stringify(event.target.innerText)))
        if ((this.record && this.record[this.keyValue].value !== newValue) || (!this.record && newValue)) {
          this.$emit('edit', newValue)
        }
      }
    },
    editOn() {
      if (this.indicator.access === 'rw') {
        this.isEdit = true
        this.$nextTick(() => {
          const el = document.getElementById(`edit_${this.uuid}`)
          el.innerText = this.record ? this.record[this.keyValue].value : '\u00a0'
          el.focus()
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
