<template>
  <div>
    <div v-if="scnBudget">
      <div v-for="scn in scnBudget.recordset.records" :key="scn.id.value">
        <kbv-budget-indicator-scenario v-if="readyInicators"
          :scenario="scn"
          :indicators="indicators.filter(i => i.access)"
          :base-year="baseYear(scn)"
          :obj-data="objData"
          :key-by-product="keyByProduct()"
          :products="products"
          :key-metric="keyMetric()"
          :key-scenario="keyScn()"
        />
      </div>
    </div>

    <cbs-data-object hidden objectsid="budgetindicator" @load="onLoadIndicator" />
    <cbs-data-object hidden objectsid="view_indicator_values_current_user" @load="onLoadObjData" />
    <cbs-data-object hidden objectsid="budget_product" @load="onLoadObjProduct" />
    <cbs-data-object hidden objectsid="scenario_budget" @load="onLoadObjScnBudget" />
    <cbs-data-object hidden objectsid="budget_indicator_access_current_user" @load="onLoadObjAccess" />
    <cbs-data-object hidden objectsid="accesstype" @load="onLoadObjAccessType" />

    <cbs-data-query hidden v-if="indicatorobj" :object="indicatorobj" :page-size="100000" @load="onLoadIndicators" />
    <cbs-data-query hidden v-if="objProduct" :object="objProduct" :page-size="100000" @load="onLoadProducts" />
    <cbs-data-query hidden v-if="objScnBudget" :object="objScnBudget" :filter="filterScnBudget()" :page-size="100000" @load="onLoadScnBudget" />

    <cbs-data-query v-if="objAccessType" :object="objAccessType" :page-size="100000" @load="onLoadQryAccessType" hidden />
    <cbs-data-query v-if="objAccess && indicators && qryAccessType" :object="objAccess" :page-size="100000" @load="onLoadQryAccess" hidden />

    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'
import CbsDataQuery from '@/cubus/components/query/CbsDataQuery.vue'
import KbvBudgetIndicatorScenario from '@/cubus/components/cbs-form/custom-forms/kbv-budget-indicator/KbvBudgetIndicatorScenario.vue'

export default {
  name: 'KbvBudgetIndicator',
  components: {
    CbsDataQuery,
    CbsDataObject,
    CbsDebug,
    KbvBudgetIndicatorScenario,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      indicatorobj: null,
      indicators: null,
      objData: null,
      qryIndicators: null,
      objProduct: null,
      products: [],
      objScnBudget: null,
      scnBudget: null,
      objAccess: null,
      objAccessType: null,
      qryAccess: null,
      qryAccessType: null,
      readyInicators: false,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      // console.log('KbvBudgetIndicator init', this.objectFull)
    },
    onLoadIndicator(obj) {
      this.indicatorobj = obj
    },
    onLoadIndicators(rs) {
      this.qryIndicators = rs
      this.indicators = rs.recordset.records
    },
    baseYear(scn) {
      return scn[this.keyBaseYear()].value || new Date().getFullYear()
    },
    onLoadObjData(obj) {
      this.objData = obj
    },
    keyByProduct() {
      if (!this.qryIndicators) return null
      const col = this.qryIndicators.columns.find(c => c.entitysid === 'by_product')
      return col ? col.key : null
    },
    keyMetric() {
      if (!this.qryIndicators) return null
      const col = this.qryIndicators.columns.find(c => c.entitysid === 'metric')
      return col ? col.key : null
    },
    onLoadObjProduct(obj) {
      this.objProduct = obj
    },
    onLoadProducts(rs) {
      this.products = rs.recordset.records
    },
    onLoadObjScnBudget(obj) {
      this.objScnBudget = obj
    },
    onLoadScnBudget(rs) {
      this.scnBudget = rs
    },
    filterScnBudget() {
      return {
        isactive: true,
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [],
          conds: [
            {
              isactive: true,
              oper: { sid: 'equal' },
              args: [
                { type: { sid: 'entitysid' }, value: 'del' },
                { type: { sid: 'value' }, value: false },
              ],
            },
          ],
        },
      }
    },
    keyScn() {
      if (this.scnBudget) {
        return this.scnBudget.columns.find(c => c.entitysid === 'scenario').key
      }
      return null
    },
    keyBaseYear() {
      if (this.scnBudget) {
        return this.scnBudget.columns.find(c => c.entitysid === 'baseyear').key
      }
      return null
    },
    onLoadObjAccess(obj) {
      this.objAccess = obj
    },
    onLoadQryAccess(obj) {
      this.qryAccess = obj
      const keyInd = this.qryAccess.columns.find(c => c.entitysid === 'budget_indicator').key
      const keyAccess = this.qryAccess.columns.find(c => c.entitysid === 'indicatoraccess').key
      this.qryAccess.recordset.records.forEach(r => {
        const ind = this.indicators.find(i => i.id.value === r[keyInd].value)
        if (ind) {
          const recAccess = r[keyAccess].value
          if (recAccess !== 0) {
            const recAccessSid = this.qryAccessType.recordset.records.find(a => a.id.value === recAccess).sid.value
            if (!ind.access) {
              this.$set(ind, 'access', recAccessSid)
            } else if (ind.access === 'ro' && recAccessSid === 'rw') {
              ind.access = 'rw'
            }
          }
        }
      })
      this.readyInicators = true
    },
    onLoadObjAccessType(obj) {
      this.objAccessType = obj
    },
    onLoadQryAccessType(obj) {
      this.qryAccessType = obj
    },
    accessibleIndicators() {
      return this.indicators.filter(i => i.access)
    },
  },
}
</script>

<style scoped>

</style>
