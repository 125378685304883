<template>
  <div>
    <div style="margin-top: 20px;">
      <b-button type="button" size="sm" variant="flat-primary" @click="expanded = !expanded">
        <feather-icon :icon="expanded ? 'ChevronDownIcon' : 'ChevronRightIcon'" size="16" />
      </b-button>
      {{ scenario[keyScenario].title }} ({{ scenario[keyScenario].value }})
    </div>
    <cbs-collapse :trigger="expanded">
      <div v-if="expanded">
        <kbv-bis-table
          :scenario="scenario"
          :indicators="indicators"
          :base-year="baseYear"
          :obj-data="objData"
          :key-by-product="keyByProduct"
          :products="products"
          :key-metric="keyMetric"
          :key-scenario="keyScenario"
        />
      </div>
    </cbs-collapse>
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import { BButton } from 'bootstrap-vue'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import cubusService from '@/cubus/services/useCubus'
import KbvBisTable from '@/cubus/components/cbs-form/custom-forms/kbv-budget-indicator/KbvBISTable.vue'

export default {
  name: 'KbvBudgetIndicatorScenario',
  components: {
    KbvBisTable,
    CbsCollapse,
    CbsDebug,
    BButton,
  },
  props: {
    scenario: {
      type: Object,
      default: null,
    },
    indicators: {
      type: Array,
      default: null,
    },
    baseYear: {
      type: Number,
      default: null,
    },
    objData: {
      type: Object,
      default: null,
    },
    keyByProduct: {
      type: String,
      default: null,
    },
    products: {
      type: Array,
      default: null,
    },
    keyMetric: {
      type: String,
      default: null,
    },
    keyScenario: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      expanded: false,
      uuid: cubusService.guid(),
    }
  },
  methods: {},
}
</script>

<style scoped>
</style>
