<template>
  <div>
    <div v-if="isReady()" style="overflow: scroll; max-height: 75vh;">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th style="position: sticky; left: 0; top: 0; z-index: 4">Показатель</th>
            <th v-for="role in qryRole.recordset.records" :key="'role_' + role.id.value" style="position: sticky; top: 0; z-index: 3;">{{ role.name.value }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ind in qryIndicator.recordset.records" :key="'ind_' + ind.id.value">
            <td style="position: sticky; left: 0; z-index: 1" class="table-light">{{ ind.name.value }}</td>
            <td v-for="role in qryRole.recordset.records" :key="'role_' + role.id.value">
              <cbs-ind-access-cell :record="getRecord(ind, role)" :key-access="keyAccess"
                                   :ro-value="roValue" :rw-value="rwValue"
                                   @update="onUpdateCell(ind, role, $event)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <cbs-data-object hidden objectsid="budgetindicatoraccess" @load="onLoadObjAccess" />
    <cbs-data-object hidden objectsid="role" @load="onLoadObjRole" />
    <cbs-data-object hidden objectsid="budgetindicator" @load="onLoadObjIndicator" />
    <cbs-data-object hidden objectsid="accesstype" @load="onLoadObjAccessType" />

    <cbs-data-query hidden v-if="objAccess" :object="objAccess" :page-size="100000" @load="onLoadQryAccess" />
    <cbs-data-query hidden v-if="objRole" :object="objRole" :page-size="100000" @load="onLoadQryRole" />
    <cbs-data-query hidden v-if="objIndicator" :object="objIndicator" :page-size="100000" @load="onLoadQryIndicator" />
    <cbs-data-query hidden v-if="objAccessType" :object="objAccessType" :page-size="100000" @load="onLoadQryAccessType" />

    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'
import CbsDataQuery from '@/cubus/components/query/CbsDataQuery.vue'
import CbsIndAccessCell from '@/cubus/components/cbs-form/custom-forms/indicator-access/CbsIndAccessCell.vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsIndicatorAccess',
  components: {
    CbsIndAccessCell,
    CbsDataQuery,
    CbsDebug,
    CbsDataObject,
  },
  props: {
    objectFull: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      objAccess: null,
      objRole: null,
      objIndicator: null,
      objAccessType: null,
      qryAccess: null,
      qryRole: null,
      qryIndicator: null,
      qryAccessType: null,
      keyIndicator: null,
      keyRole: null,
      keyAccess: null,
      roValue: null,
      rwValue: null,
    }
  },
  methods: {
    onLoadObjAccess(obj) {
      this.objAccess = obj
    },
    onLoadObjRole(obj) {
      this.objRole = obj
    },
    onLoadObjIndicator(obj) {
      this.objIndicator = obj
    },
    onLoadQryAccess(obj) {
      this.qryAccess = obj
      this.keyRole = this.qryAccess.columns.find(c => c.entitysid === 'role').key
      this.keyIndicator = this.qryAccess.columns.find(c => c.entitysid === 'budget_indicator').key
      this.keyAccess = this.qryAccess.columns.find(c => c.entitysid === 'indicatoraccess').key
    },
    onLoadQryRole(obj) {
      this.qryRole = obj
    },
    onLoadQryIndicator(obj) {
      this.qryIndicator = obj
    },
    onLoadObjAccessType(obj) {
      this.objAccessType = obj
    },
    onLoadQryAccessType(obj) {
      this.qryAccessType = obj
      this.roValue = this.qryAccessType.recordset.records.find(r => r.sid.value === 'ro').id.value
      this.rwValue = this.qryAccessType.recordset.records.find(r => r.sid.value === 'rw').id.value
    },
    getRecord(ind, role) {
      if (this.qryAccess) {
        const rec = this.qryAccess.recordset.records.find(r => r[this.keyIndicator].value === ind.id.value && r[this.keyRole].value === role.id.value)
        if (rec) return rec
        const newrec = this.getNewRecord(ind, role)
        this.qryAccess.recordset.records.push(newrec)
        return newrec
      }
      return null
    },
    getNewRecord(ind, role) {
      const rec = {}
      this.qryAccess.columns.forEach(c => {
        if (c.key !== 'actions') {
          rec[c.key] = { value: null, title: null }
        }
      })
      rec.del.value = false
      rec[this.keyIndicator] = { value: ind.id.value, title: ind.name.value }
      rec[this.keyRole] = { value: role.id.value, title: role.name.value }
      return JSON.parse(JSON.stringify(rec))
    },
    onUpdateCell(ind, role, value) {
      const rec = this.getRecord(ind, role)
      if (value === 'no') {
        rec[this.keyAccess].value = null
        rec[this.keyAccess].title = null
      } else {
        const acc = this.qryAccessType.recordset.records.find(r => r.sid.value === value)
        rec[this.keyAccess].value = acc.id.value
        rec[this.keyAccess].title = acc.name.value
      }
      rec[this.keyAccess].status = 'changed'
      this.saveRecord(rec, 'indicatorAccess')
    },
    saveRecord(record, caller) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'saverecord',
          param: {
            objectid: this.objAccess.object.id,
            record,
          },
        },
      })
        .then(response => {
          console.log('save record response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.threadSaveRecord(response.data.thread, record, caller)
          } else {
            useCubus.toastError(this, 'No thread name provided.')
          }
        })
        .catch(error => {
          console.log('save record error', error)
        })
    },
    threadSaveRecord(thread, record, caller) {
      useJwt.query({ query: { method: 'thread', param: { threadname: thread } } })
        .then(response => {
          console.log('threadSaveRecord response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread.status === 'error') {
            useCubus.toastError(this, response.data.thread.error)
          } else if (response.data.thread.status === 'done') {
            this.afterSave(record, response.data.thread.result.record)
          } else {
            this.delaySaveRecord(thread, record, caller)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    delaySaveRecord(thread, record, caller) {
      setTimeout(() => this.threadSaveRecord(thread, record, caller), 200)
    },
    afterSave(initRec, savedRec) {
      savedRec.status = 'saved'
      this.qryAccess.columns.forEach(fld => {
        initRec[fld.key] = savedRec[fld.key]
      })
      initRec.status = 'saved'
    },
    isReady() {
      return this.objAccess && this.objRole && this.objIndicator && this.objAccessType && this.qryAccess && this.qryRole && this.qryIndicator && this.qryAccessType
    },
  },
}
</script>

<style scoped>

</style>
